import * as React from "react";

import {
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  EditButton,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import { IShipping } from "types";
import RichTextInput from "ra-input-rich-text";

export const PaymentList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="title" />
      <NumberField
        source="price"
        options={{ style: "currency", currency: "CZK" }}
      />
      <EditButton label="" />
    </Datagrid>
  </List>
);

const Form = props => (
  <SimpleForm {...props}>
    <TextInput disabled source="id" />
    <TextInput source="title" />
    <NumberInput source="price" />
    <ReferenceArrayInput source="compatible_shipping" reference="shipping">
      <SelectArrayInput
        optionText={(s: IShipping) => `${s.title} - ${s.price}`}
      />
    </ReferenceArrayInput>
    <BooleanInput source="is_cod" initialValue={false} />
    <RichTextInput
      source="message_to_customer"
      helperText="V textu můžete použít proměnné: celková cena {total_price}, číslo objednávky {id}"
    />
  </SimpleForm>
);

export const PaymentCreate = props => (
  <Create {...props}>
    <Form />
  </Create>
);

export const PaymentEdit = props => (
  <Edit {...props}>
    <Form />
  </Edit>
);
