import * as React from "react";

import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FormDataConsumer,
  FormWithRedirect,
  List,
  NumberField,
  ReferenceInput,
  ReferenceManyField,
  SaveButton,
  SearchInput,
  SelectField,
  SelectInput,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  useMutation,
  useNotify,
  useQuery,
  useQueryWithStore,
  useRefresh,
  useTranslate,
} from "react-admin";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Grid,
  TextField as MaterialTextField,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { LocalShipping } from "@material-ui/icons";
import { OrderStatus, OrderStatusTranslate } from "types";
import firebase from "firebase/app";

import SearchFilter from "../SearchFilter";

const OrderFilter = props => (
  <SearchFilter {...props} searchSources={["contact_name"]}>
    <SelectInput source="status" choices={statusOptions} alwaysOn />
    <SearchInput source="contact_name" alwaysOn />
  </SearchFilter>
);

const Products: React.FC<{ record?: any; label: string }> = ({ record }) => {
  const request = useQuery({
    type: "getList",
    resource: "order_product",
    payload: {
      filter: {
        order_id: record.id,
      },
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: {
        field: "id",
        order: "ASC",
      },
    },
  });
  return (
    <span>
      {request?.data?.map(p => (
        <Chip key={p?.id} label={p?.product_name} size="small" />
      ))}
    </span>
  );
};

const hostname = window.location.hostname;
const functionUrl =
  window.location.protocol +
  "//" +
  (hostname.startsWith("admin.")
    ? hostname.replace(/^admin\./, "")
    : hostname) +
  "/__ext/ppl/export-orders";

const ExportPPLButton = ({ selectedIds }) => (
  <Button
    startIcon={<LocalShipping />}
    size="small"
    onClick={async () => {
      const orders = selectedIds.join(",");
      const token = await firebase.auth().currentUser?.getIdToken();
      window.open(functionUrl + "?orders=" + orders + "&token=" + token);
    }}
  >
    PPL Export ({selectedIds.length})
  </Button>
);

const PostBulkActionButtons = props => (
  <>
    <ExportPPLButton label="PPL" {...props} />
  </>
);

export const OrderList = props => (
  <List
    {...props}
    sort={{ field: "id", order: "DESC" }}
    filters={<OrderFilter />}
    bulkActionButtons={<PostBulkActionButtons />}
  >
    <Datagrid rowClick="edit">
      <NumberField source="id" />
      <DateField source="created_at" showTime />
      <ReferenceManyField
        label="status"
        reference="order_update"
        target="order_id"
        perPage={1}
        sort={{ field: "created_at", order: "DESC" }}
      >
        <SingleFieldList>
          <SelectField source="status" choices={statusOptions} />
        </SingleFieldList>
      </ReferenceManyField>
      <TextField source="contact_name" />
      <Products label="Products" />
      <NumberField
        source="total_price"
        options={{ style: "currency", currency: "CZK" }}
      />
    </Datagrid>
  </List>
);

const statusOptions = [
  "INITIAL",
  "CONFIRMED",
  "IN_PROGRESS",
  "SENT_TO_CUSTOMER",
  "DONE",
  "WAITING_FOR_PAYMENT",
  "WAITING_FOR_STOCK",
  "CANCELLED_BY_STORE",
  "CANCELLED_BY_CUSTOMER",
  "RETURNED",
].map(s => ({ id: s, name: OrderStatusTranslate[s] })) as {
  id: OrderStatus;
  name: string;
}[];

const Form = props => {
  const _ = useTranslate();
  const statusRequest = useQueryWithStore({
    type: "getList",
    resource: "order_update",
    payload: {
      filter: {
        order_id: props.record.id,
      },
      pagination: {
        page: 1,
        perPage: 1,
      },
      sort: {
        field: "created_at",
        order: "DESC",
      },
    },
  });
  const status = statusRequest?.data?.[0]?.status ?? "INITIAL";
  const statusTranslate = OrderStatusTranslate?.[status] ?? status;

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
          <Box p="1em" display="flex" flexDirection="column">
            <Typography>Stav objednávky: {statusTranslate}</Typography>
            <ReferenceManyField
              reference="order_product"
              target="order_id"
              label="products"
              {...props}
            >
              <Datagrid>
                <TextField
                  source="product_name"
                  label={_("resources.orders.fields.product_name")}
                />
                <TextField
                  source="gift_product_name"
                  label={_("resources.orders.fields.gift_product_name")}
                />
                <TextField
                  source="quantity"
                  label={_("resources.orders.fields.quantity")}
                />
                <TextField
                  source="product_price"
                  label={_("resources.orders.fields.product_price")}
                />
                <EditButton />
              </Datagrid>
            </ReferenceManyField>

            <Grid container spacing={2} alignItems="center">
              <Grid item md={2}>
                <BooleanInput
                  source="payment_is_company"
                  label={_("resources.orders.fields.payment_is_company")}
                />
              </Grid>
              <FormDataConsumer>
                {({ formData }) =>
                  formData.payment_is_company ? (
                    <>
                      <Grid item md={4}>
                        <TextInput
                          source="payment_company_name"
                          label={_(
                            "resources.orders.fields.payment_company_name"
                          )}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextInput
                          source="payment_company_id"
                          label={_(
                            "resources.orders.fields.payment_company_id"
                          )}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextInput
                          source="payment_company_vatId"
                          label={_(
                            "resources.orders.fields.payment_company_vatId"
                          )}
                          fullWidth
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item md={10} />
                  )
                }
              </FormDataConsumer>

              <Grid item md={4}>
                <TextInput
                  source="contact_name"
                  label={_("resources.orders.fields.contact_name")}
                  fullWidth
                />
              </Grid>
              <Grid item md={4}>
                <TextInput
                  source="contact_email"
                  label={_("resources.orders.fields.contact_email")}
                  type="email"
                  fullWidth
                />
              </Grid>
              <Grid item md={4}>
                <TextInput
                  source="contact_phone"
                  label={_("resources.orders.fields.contact_phone")}
                  type="tel"
                  fullWidth
                />
              </Grid>

              <Grid item md={7}>
                <TextInput
                  source="payment_address"
                  label={_("resources.orders.fields.payment_address")}
                  fullWidth
                />
              </Grid>
              <Grid item md={3}>
                <TextInput
                  source="payment_city"
                  label={_("resources.orders.fields.payment_city")}
                  fullWidth
                />
              </Grid>
              <Grid item md={2}>
                <TextInput
                  source="payment_zip"
                  label={_("resources.orders.fields.payment_zip")}
                  fullWidth
                />
              </Grid>

              <Grid item md={2}>
                <BooleanInput
                  source="shipping_address_is_other"
                  label={_("resources.orders.fields.shipping_address_is_other")}
                />
              </Grid>
              <FormDataConsumer>
                {({ formData }) =>
                  formData.shipping_address_is_other ? (
                    <>
                      <Grid item md={5}>
                        <TextInput
                          source="shipping_address"
                          label={_("resources.orders.fields.shipping_address")}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={3}>
                        <TextInput
                          source="shipping_city"
                          label={_("resources.orders.fields.shipping_city")}
                          fullWidth
                        />
                      </Grid>
                      <Grid item md={2}>
                        <TextInput
                          source="shipping_zip"
                          label={_("resources.orders.fields.shipping_zip")}
                          fullWidth
                        />
                      </Grid>
                    </>
                  ) : (
                    <Grid item md={10} />
                  )
                }
              </FormDataConsumer>

              <Grid item md={2}>
                <TextInput
                  source="shipping_name"
                  label={_("resources.orders.fields.shipping_name")}
                />
              </Grid>
              <Grid item md={2}>
                <TextInput
                  source="shipping_price"
                  label={_("resources.orders.fields.shipping_price")}
                />
              </Grid>
              <Grid item md={2} />
              <Grid item md={2}>
                <ReferenceInput
                  source="payment_id"
                  label={_("resources.orders.fields.payment_id")}
                  reference="payment"
                >
                  <SelectInput
                    optionText={item => `${item.title} - ${item.price} Kč`}
                    fullWidth
                  />
                </ReferenceInput>
              </Grid>
              <Grid item md={2}>
                <TextInput
                  source="payment_price"
                  label={_("resources.orders.fields.payment_price")}
                />
              </Grid>
              <Grid item md={2}>
                <TextInput
                  source="total_price"
                  label={_("resources.orders.fields.total_price")}
                />
              </Grid>

              <Grid item md={12}>
                <TextInput
                  source="notes"
                  label={_("resources.orders.fields.notes")}
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>

            <ReferenceManyField
              reference="order_update"
              target="order_id"
              sort={{ field: "created_at", order: "ASC" }}
              {...props}
            >
              <Datagrid>
                <DateField
                  source="created_at"
                  label={_("resources.order_update.fields.created_at")}
                  showTime
                />
                <SelectField
                  source="status"
                  label={_("resources.order_update.fields.status")}
                  choices={statusOptions}
                />
                <TextField
                  source="message"
                  label={_("resources.order_update.fields.message")}
                />
                <BooleanField
                  source="notify_customer"
                  label={_("resources.order_update.fields.notify_customer")}
                />
              </Datagrid>
            </ReferenceManyField>

            <AddUpdateForm {...props} />
          </Box>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
              <SaveButton
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            </Box>
          </Toolbar>
        </form>
      )}
    />
  );
};

const AddUpdateForm = ({ record }) => {
  const [status, setStatus] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [notifyCustomer, setNotifyCustomer] = React.useState<boolean>(true);
  const notify = useNotify();
  const refresh = useRefresh();
  const [update, { loading }] = useMutation(
    {
      type: "create",
      resource: "order_update",
      payload: {
        data: {
          order_id: record.id,
          system_message: false,
          notify_customer: notifyCustomer,
          status,
          message,
        },
      },
    },
    {
      onSuccess: () => {
        setMessage("");
        notify("Objednávka upravena", "info");
        refresh();
      },
    }
  );
  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <MaterialTextField
          label="Status"
          variant="filled"
          size="small"
          fullWidth
          select
          value={status}
          onChange={e => setStatus(e.target.value)}
        >
          {statusOptions.map(s => (
            <MenuItem key={s.id} value={s.id}>
              {s.name}
            </MenuItem>
          ))}
        </MaterialTextField>
      </Grid>
      <Grid item xs={8}>
        <MaterialTextField
          label="Zpráva"
          variant="filled"
          size="small"
          fullWidth
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
      </Grid>
      <Grid item xs={2}>
        <Checkbox
          checked={notifyCustomer}
          onChange={e => setNotifyCustomer(e.target.checked)}
        />
        Poslat email
        <Button variant="outlined" onClick={update} disabled={loading}>
          {loading ? "Načítání" : "Přidat"}
        </Button>
      </Grid>
    </Grid>
  );
};

export const OrderEdit = props => (
  <Edit undoable={false} {...props}>
    <Form />
  </Edit>
);
