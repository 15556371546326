import * as React from "react";

import {
  BooleanInput,
  CloneButton,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  FileInput,
  FormTab,
  ImageField,
  Labeled,
  List,
  NumberField,
  NumberInput,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  useDataProvider,
  useGetList,
  useMutation,
  useTranslate,
} from "react-admin";
import { Grid, InputAdornment } from "@material-ui/core";
import { IShipping } from "types";
import { useForm } from "react-final-form";
import RichTextInput from "ra-input-rich-text";

import "./products.css";
import SearchFilter from "../SearchFilter";

const ProductFilter = props => (
  <SearchFilter {...props} searchSources={["title"]}>
    <SearchInput source="title" alwaysOn />
    <BooleanInput source="status" />
    <BooleanInput source="special_offer" />
    <ReferenceInput source="category_id" reference="categories">
      <SelectInput optionText="title" />
    </ReferenceInput>
  </SearchFilter>
);

const ChangeableBoolean = ({ source, record, resource }: any) => {
  const [mutate, { loading }] = useMutation();
  return (
    <span
      onClick={e => {
        e.stopPropagation();
        mutate({
          type: "update",
          resource: resource,
          payload: {
            id: record.id,
            data: {
              ...record,
              [source]: !record[source],
            },
            previousData: record,
          },
        });
      }}
      style={{
        color: record[source] ? "#3d841b" : "#e83726",
        padding: 10,
      }}
      className="hover-bold"
    >
      {loading ? "ukládání" : record[source] ? "ANO" : "NE"}
    </span>
  );
};

export const ProductList = props => (
  <List {...props} filters={<ProductFilter />}>
    <Datagrid rowClick="edit">
      <ChangeableBoolean source="status" />
      <TextField source="title" />
      <NumberField
        source="special_price"
        options={{ style: "currency", currency: "CZK" }}
      />
      <NumberField
        source="price"
        options={{ style: "currency", currency: "CZK" }}
      />
      <CloneButton />
      <DeleteButton label="" redirect={false} />
    </Datagrid>
  </List>
);

const GiftsInput = props => {
  const form = useForm();
  const dataProvider = useDataProvider();
  const productId = form.getState().values?.id;
  const [search, setSearch] = React.useState("");
  const searchLowercase = search.toLowerCase();

  const { data: selected } = useGetList(
    "product_gifts",
    {
      page: 1,
      perPage: 10000,
    },
    { field: "product.title", order: "ASC" },
    { product_id: parseInt(productId) }
  );

  const { data: products } = useGetList(
    "products",
    {
      page: 1,
      perPage: 10000,
    },
    { field: "title", order: "ASC" },
    {}
  );

  const handleSelect = async (giftId: string) => {
    await dataProvider.create("product_gifts", {
      data: { product_id: parseInt(productId), gift_id: parseInt(giftId) },
    });

    // Refresh list of gifts
    await dataProvider.getList("product_gifts", {
      pagination: {
        page: 1,
        perPage: 10000,
      },
      sort: { field: "product.title", order: "ASC" },
      filter: { product_id: parseInt(productId) },
    });
  };

  const handleUnselect = async (relationId: string) => {
    await dataProvider.delete("product_gifts", {
      id: parseInt(relationId),
      previousData: {},
    });
  };

  return (
    <Labeled source={props.source}>
      <table className="gifts">
        <thead>
          <tr>
            <th>Vybrané produkty</th>
            <th>Cena</th>
          </tr>
        </thead>
        <tbody className="selected">
          {Object.keys(selected)?.map(relationId => {
            const relation = selected[relationId];
            if (!products?.[relation.gift_id]) return null;
            return (
              <tr key={relationId} onClick={() => handleUnselect(relationId)}>
                <td>{products?.[relation.gift_id]?.title}</td>
                <td align="right">
                  {Math.round(products?.[relation.gift_id]?.price)}
                </td>
              </tr>
            );
          })}
        </tbody>
        <thead>
          <tr>
            <th>
              Vyhledávání produktů{" "}
              <input
                type="text"
                value={search}
                onChange={e => setSearch(e.target.value)}
              />
            </th>
            <th align="right">Cena</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(products)
            ?.filter(
              giftId =>
                // cannot set self as gift
                giftId !== "" + productId &&
                // hide already selected
                !Object.keys(selected).find(
                  referenceId => "" + selected[referenceId].gift_id === giftId
                ) &&
                // filter by search
                (searchLowercase
                  ? products[giftId].title
                      .toLowerCase()
                      .includes(searchLowercase)
                  : true)
            )
            ?.map(giftId => (
              <tr key={giftId} onClick={() => handleSelect(giftId)}>
                <td>{products[giftId].title}</td>
                <td align="right">{Math.round(products[giftId].price)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </Labeled>
  );
};

const useSource = () => {
  const t = useTranslate();
  return (source: string) => ({
    source,
    label: t("resources.products.fields." + source),
  });
};

const Form = props => {
  const t = useTranslate();
  const source = useSource();
  return (
    <TabbedForm {...props}>
      <FormTab label={t("resources.products.tabs.summary")}>
        <BooleanInput source="status" initialValue={true} />
        <Grid direction="row" container spacing={2} style={{ width: "100%" }}>
          <Grid item md={4}>
            <TextInput {...source("title")} required fullWidth />
          </Grid>
          <Grid item md={3}>
            <TextInput {...source("model")} required fullWidth />
          </Grid>
          <Grid item md={3}>
            <ReferenceInput
              {...source("manufacturer_id")}
              reference="manufacturers"
              sort={{ field: "name", order: "ASC" }}
              perPage={1000}
              fullWidth
            >
              <SelectInput optionText="name" required fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item md={2}>
            <TextInput {...source("ean")} label="EAN" fullWidth />
          </Grid>
        </Grid>
        <Grid direction="row" container spacing={2} style={{ width: "100%" }}>
          <Grid item md={2}>
            <NumberInput {...source("price")} required fullWidth />
          </Grid>
          <Grid item md={2}>
            <NumberInput {...source("special_price")} fullWidth />
          </Grid>
          <Grid item md={2}>
            <SelectInput
              {...source("availability_days")}
              choices={[
                { id: 1, name: "Skladem u nás" },
                { id: 3, name: "Na skladě dodavatele - 3 dny" },
                { id: 5, name: "Na skladě dodavatele - 5 dnů" },
                { id: 7, name: "Na skladě dodavatele - 7 dní" },
                { id: 14, name: "Na skladě dodavatele - 14 dní" },
                { id: 99, name: "Nedostupné" },
                { id: 100, name: "Ověřte aktuální dostupnost" },
              ]}
              required
            />
          </Grid>
          <Grid item md={2}>
            <ReferenceInput
              {...source("shipping_id")}
              reference="shipping"
              filter={{ enable_for_all_products: false }}
              fullWidth
            >
              <SelectInput
                optionText={(s: IShipping) => `${s.title} - ${s.price}`}
                required
              />
            </ReferenceInput>
          </Grid>
          <Grid item md={4}>
            <ReferenceInput
              {...source("category_id")}
              reference="categories"
              sort={{ field: "title", order: "ASC" }}
              perPage={1000}
              fullWidth
            >
              <SelectInput optionText="title" required fullWidth />
            </ReferenceInput>
          </Grid>
        </Grid>
        <TextInput source="comment" fullWidth />
        <RichTextInput source="description" required />
        <Grid direction="row" container spacing={2} style={{ width: "100%" }}>
          <Grid item md={1}>
            <NumberInput
              source="height"
              label={t("resources.products.fields.height")}
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              fullWidth
            />
          </Grid>
          <Grid item md={1}>
            <NumberInput
              source="width"
              label={t("resources.products.fields.width")}
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              fullWidth
            />
          </Grid>
          <Grid item md={1}>
            <NumberInput
              source="long"
              label={t("resources.products.fields.long")}
              InputProps={{
                endAdornment: <InputAdornment position="end">m</InputAdornment>,
              }}
              fullWidth
            />
          </Grid>
          <Grid item md={1}>
            <NumberInput
              source="weight"
              label={t("resources.products.fields.weight")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">kg</InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label={t("resources.products.tabs.images")}>
        <FileInput source="images" multiple>
          <ImageField source="src" title="title" />
        </FileInput>
      </FormTab>
      <FormTab label={t("resources.products.tabs.gifts")}>
        <TextInput source="ext_gift_text" fullWidth />
        <GiftsInput source="gift_product_ids" />
      </FormTab>
      <FormTab label={t("resources.products.tabs.extension")}>
        <NumberInput
          source="ext_heureka_max_cpc"
          helperText="Maximální cena za proklik v Kč"
        />
        <NumberInput
          source="ext_zbozi_max_cpc"
          helperText="Maximální cena za proklik v detailu produktu v Kč"
        />
        <NumberInput
          source="ext_zbozi_max_cpc_search"
          helperText="Maximální cena za proklik ve fulltextovém výsledku hledání v Kč"
        />
      </FormTab>
    </TabbedForm>
  );
};

export const ProductCreate = props => (
  <Create {...props}>
    <Form />
  </Create>
);

const ProductEditTitle = ({ record }: any) => (
  <span>Produkt {record ? `${record.id}, ${record.title}` : ""}</span>
);

export const ProductEdit = props => (
  <Edit title={<ProductEditTitle />} undoable={false} {...props}>
    <Form />
  </Edit>
);
