import { CREATE, UPDATE } from "react-admin";
import { LegacyDataProvider } from "ra-core/esm/types.d";
import { set } from "lodash";
import { v4 as uuid } from "uuid";

import "firebase/storage";
import firebase from "firebase/app";

const FirebaseStorageProvider = (
  dataProvider: LegacyDataProvider
): LegacyDataProvider => async (
  type: string,
  resourceName: string,
  params: any
): Promise<any> => {
  if (type === CREATE || type === UPDATE) {
    const uploads = getAllUploads(params.data);
    await Promise.all(
      uploads.map(async u => {
        const link = await saveFile(resourceName + "/" + uuid(), u.rawFile);
        set(params.data, u.fieldDotsPath + ".src", link);
      })
    );
  }
  return await dataProvider(type, resourceName, params);
};

interface ParsedUpload {
  fieldDotsPath: string;
  fieldSlashesPath: string;
  rawFile: File | any;
}
function getAllUploads(obj: {}): ParsedUpload[] {
  const isObject = !!obj && typeof obj === "object";
  if (!isObject) {
    return [];
  }
  const uploads = [];
  Object.keys(obj).forEach(key => {
    const value = obj[key];
    recursivelyParseObjectValue(value, key, uploads);
  });
  return uploads;
}

function recursivelyParseObjectValue(
  input: any,
  fieldPath: string,
  uploads: ParsedUpload[]
) {
  const isArray = Array.isArray(input);
  if (isArray) {
    return (input as []).map((value, index) =>
      recursivelyParseObjectValue(value, `${fieldPath}.${index}`, uploads)
    );
  }
  const isFileField = !!input && input.hasOwnProperty("rawFile");
  if (isFileField) {
    uploads.push({
      fieldDotsPath: fieldPath,
      fieldSlashesPath: fieldPath.split(".").join("/"),
      rawFile: input.rawFile,
    });
    delete input.rawFile;
    return;
  }
  const isObject = !!input && typeof input === "object";
  if (isObject) {
    Object.keys(input).forEach(key => {
      const value = input[key];
      recursivelyParseObjectValue(value, `${fieldPath}.${key}`, uploads);
    });
  }
  return input;
}

async function saveFile(
  storagePath: string,
  rawFile: any
): Promise<string | undefined> {
  console.log("saveFile() saving file...", { storagePath, rawFile });
  const task = firebase
    .storage()
    .ref(storagePath)
    .put(rawFile);
  try {
    const taskResult: firebase.storage.UploadTaskSnapshot = await new Promise(
      (res, rej) => task.then(res).catch(rej)
    );
    const getDownloadURL = await taskResult.ref.getDownloadURL();
    console.log("saveFile() saved file", {
      storagePath,
      taskResult,
      getDownloadURL,
    });
    return getDownloadURL;
  } catch (storageError) {
    if (storageError.code === "storage/unknown") {
      console.error(
        'saveFile() error saving file, No bucket found! Try clicking "Get Started" in firebase -> storage',
        { storageError }
      );
    } else {
      console.error("saveFile() error saving file", {
        storageError,
      });
    }
  }
}

export default FirebaseStorageProvider;
