import * as React from "react";

import { Button, Grid } from "@material-ui/core";
import {
  Create,
  Datagrid,
  DateField,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  FormDataConsumer,
  FormWithRedirect,
  List,
  NumberField,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import firebase from "firebase/app";
import firebaseConfig from "../firebase-config.json";

export const DiscountCodeList = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="code" />
      <NumberField
        source="discount"
        options={{ style: "currency", currency: "CZK" }}
      />
      <NumberField
        source="min_price"
        options={{ style: "currency", currency: "CZK" }}
      />
      <NumberField source="count_of_used" />
      <NumberField source="limit" />
      <DateField source="expiration" />
      <DeleteButton label="" />
      <EditButton label="" />
    </Datagrid>
  </List>
);

const functionUrl =
  process.env.NODE_ENV === "production"
    ? `https://europe-west1-${firebaseConfig.projectId}.cloudfunctions.net/functionGenerateDiscountCodes`
    : `http://localhost:5001/${firebaseConfig.projectId}/europe-west1/functionGenerateDiscountCodes`;

export const DiscountCodeCreate = props => (
  <Create {...props}>
    <FormWithRedirect
      render={() => (
        <form>
          <Grid container direction="column" style={{ margin: 20 }}>
            <Grid item>
              <NumberInput source="count" required />
            </Grid>
            <Grid item>
              <RichTextInput source="description" required />
            </Grid>
            <Grid item>
              <NumberInput source="discount" required />
            </Grid>
            <Grid item>
              <NumberInput source="min_price" required />
            </Grid>
            <Grid item>
              <NumberInput source="limit" required />
            </Grid>
            <Grid item>
              <DateInput source="expiration" />
            </Grid>
          </Grid>
          <Toolbar>
            <FormDataConsumer>
              {({ formData }) => (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={async () =>
                    window.open(
                      functionUrl +
                        "?" +
                        new URLSearchParams({
                          ...formData,
                          token: await firebase
                            .auth()
                            .currentUser?.getIdToken(),
                        })
                    )
                  }
                >
                  Generovat
                </Button>
              )}
            </FormDataConsumer>
          </Toolbar>
        </form>
      )}
    />
  </Create>
);

export const DiscountCodeEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <TextInput source="code" required />
      <NumberInput source="discount" required />
      <NumberInput source="min_price" required />
      <NumberInput source="count_of_used" required />
      <NumberInput source="limit" required />
      <DateInput source="expiration" />
    </SimpleForm>
  </Edit>
);
