export default {
  ra: {
    action: {
      delete: "Smazat",
      show: "Ukázat",
      list: "Seznam",
      save: "Uložit",
      create: "Vytvořit",
      edit: "Upravit",
      export: "Exportovat",
      sort: "Seřadit",
      cancel: "Zrušit",
      undo: "Vrátit",
      refresh: "Obnovit",
      add: "Přidat",
      remove: "Odstranit",
      add_filter: "Přidat filtr",
      remove_filter: "Odstranit filtr",
      back: "Jít zpět",
      bulk_actions: "%{smart_count} vybráno",
      clear_input_value: "Smazat",
      clone: "Klonovat",
      confirm: "Potvrdit",
      search: "Hledat",
      expand: "Otevřít",
      close: "Zavřít",
      open_menu: "Otevřít menu",
      close_menu: "Zavřít menu",
      unselect: "Zrušit označení",
    },
    boolean: {
      true: "Ano",
      false: "Ne",
      null: "Žádná hodnota",
    },
    page: {
      empty: "Žádná data",
      invite: "Vytvořte první záznam",
      list: "%{name} Seznam",
      edit: "%{name} #%{id}",
      show: "%{name} #%{id}",
      create: "Vytvořit %{name}",
      dashboard: "Dashboard",
      not_found: "Nenalezeno",
      loading: "Načítání",
      error: "Chyba",
    },
    input: {
      password: {
        toggle_visible: "Zobrazit",
        toggle_hidden: "Schovat",
      },
      file: {
        upload_several:
          "Přetáhněte soubory pro nahrání nebo klikněte pro výběr",
        upload_single:
          "Přetáhněte soubor pro nahrání nebo klikněte pro jeho výběr",
      },
      image: {
        upload_several:
          "Přetáhněte obrázky pro nahrání nebo klikněte pro výběr",
        upload_single:
          "Přetáhněte obrázek pro nahrání nebo klikněte pro jeho výběr",
      },
      references: {
        all_missing: "Nelze nalézt referencovaná data",
        many_missing:
          "Minimálně jedna z referencí se nezdá býti nadále dostupná",
        single_missing: "Reference se nezdá býti nadále dostupná.",
      },
    },
    message: {
      yes: "Ano",
      no: "Ne",
      are_you_sure: "Jste si jistý?",
      about: "O",
      not_found: "Nic nebylo nalezeno",
      loading: "Stránka se načítá, prosím strpení",
      invalid_form: "Formulář není validní. Prosím zkontrolujte chyby.",
      delete_title: "Smazat %{name} #%{id}",
      delete_content: "Jste si jistý, že chcete smazat tuto položku?",
      bulk_delete_title:
        "Smazat %{name} |||| Smazat %{smart_count} %{name} položek",
      bulk_delete_content:
        "Jste si jistý, že chcete smazat %{name}? |||| Jste si jistý, že chcete smazat těchto %{smart_count} položek?",
      details: "Detaily",
      error: "Chyba",
      unsaved_changes: "Neuložené změny",
    },
    navigation: {
      no_results: "Žádné výsledky nenalezeny",
      no_more_results:
        "Stránka číslo %{page} je mimo rozsah. Zkuste předchozí.",
      page_out_of_boundaries: "Stránka číslo %{page} je mimo rozsah",
      page_out_from_end: "Nelze se přepnout za poslední stranou",
      page_out_from_begin: "Nelze se přepnout před stranu 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} z %{total}",
      page_rows_per_page: "Řádků na stránku:",
      next: "Další",
      prev: "Předchozí",
    },
    auth: {
      username: "Uživatelské jméno",
      password: "Heslo",
      sign_in: "Přihlásit se",
      sign_in_error: "Ověření selhalo, zkuste to znovu",
      logout: "Odhlásit se",
      auth_check_error: "Chyba přihlášení",
      user_menu: "Uživatelské menu",
    },
    notification: {
      updated: "Prvek aktualizován |||| %{smart_count} prvků aktualizováno",
      created: "Prvek vytvořen",
      deleted: "Prvek smazán |||| %{smart_count} prvků smazáno",
      bad_item: "Nesprávný prvek",
      item_doesnt_exist: "Prvek neexistuje",
      http_error: "Chyba komunikace serveru",
      canceled: "Akce zrušena",
      data_provider_error: "Chyba v data provider",
      i18n_error: "Chyba překladu",
      logged_out: "Účet je odhlášen",
    },
    validation: {
      required: "Povinné pole",
      minLength: "Musí obsahovat nejméně %{min} znaků",
      maxLength: "Může obsahovat maximálně %{max} znaků",
      minValue: "Musí být minimálně %{min}",
      maxValue: "Múže být maximálně %{max}",
      number: "Musí být číslo",
      email: "Musí být validní emailová adresa",
      oneOf: "Musí splňovat jedno z: %{options}",
      regex: "Musí být ve specifickém formátu (regexp): %{pattern}",
    },
  },
  resources: {
    orders: {
      name: "Objednávky",
      fields: {
        status: "Stav",
        product_name: "Produkt",
        gift_product_name: "Dárek",
        quantity: "Počet",
        product_price: "Cena",
        payment_is_company: "Firma?",
        payment_company_name: "Název firmy",
        payment_company_id: "IČ",
        payment_company_vatId: "DIČ",
        contact_name: "Jméno",
        contact_email: "E-mail",
        contact_phone: "Telefon",
        payment_address: "Fakturační adresa",
        payment_city: "Město",
        payment_zip: "PSČ",
        shipping_address_is_other: "Jiná dodací adresa?",
        shipping_address: "Dodací adresa",
        shipping_city: "Město",
        shipping_zip: "PSČ",
        shipping_name: "Doprava",
        shipping_price: "Cena dopravy",
        payment_id: "Platba",
        payment_price: "Cena platby",
        total_price: "Celková cena objednávky",
        notes: "Poznámka zákazníka",
        products: "Produkty",
      },
    },
    order_update: {
      name: "Úpravy objednávky",
      fields: {
        created_at: "Vytvořeno",
        status: "Stav",
        message: "Zpráva",
        notify_customer: "Odeslat e-mail zákazníkovi",
      },
    },
    products: {
      name: "Produkty",
      tabs: {
        summary: "Souhrn",
        images: "Obrázky",
        gifts: "Dárky",
        extension: "Rozšíření",
      },
      fields: {
        status: "Produkt je aktivní",
        title: "Název",
        model: "Model",
        ean: "Ean",
        price: "Cena",
        special_price: "Speciální cena",
        availability_days: "Dostupnost",
        shipping_id: "Doprava",
        category_id: "Kategorie",
        manufacturer_id: "Výrobce",
        comment: "Komentář",
        description: "Popis",
        height: "Výška",
        width: "Šířka",
        long: "Délka",
        weight: "Hmotnost",
        images: "Obrázky",
        gift_product_ids: "Dárky",
        special_offer: "Zobrazit ve speciální nabídce",
        ext_gift_text: "Dárek zobrazený v Heurece / Zbozi.cz",
        ext_heureka_max_cpc: "CPC Heureka",
        ext_zbozi_max_cpc: "CPC Zboží.cz produkt",
        ext_zbozi_max_cpc_search: "CPC Zboží.cz hledání",
      },
    },
    categories: {
      name: "Kategorie",
      fields: {
        status: "Stav",
        title: "Název",
        parent: "Nadřazená kategorie",
        ext_zbozicz_categorytext: "kategorie dle Zboží.cz",
      },
    },
    shipping: {
      name: "Doprava",
      fields: {
        title: "Název",
        price: "Cena",
        enable_for_all_products:
          "Povolit pro všechny produkty, např. osobní odběr",
        ext_heureka_delivery: "Heureka doprava",
        ext_zbozicz_delivery: "Zboží.cz doprava",
        delivery_id: "Kód dopravy",
        delivery_price_cod: "Cena dobirky",
      },
    },
    payment: {
      name: "Platba",
      fields: {
        title: "Název",
        price: "Cena",
        is_cod: "Je tato platba dobírka?",
        compatible_shipping: "Kompatibilní doprava",
        message_to_customer: "Zpráva zákazníkovi",
      },
    },
    discount_codes: {
      name: "Slevové kupóny",
      fields: {
        generate_count: "Počet kupónů k vygenerování",
        code: "Kód",
        discount: "Sleva Kč",
        min_price: "Minimální objednávka",
        count_of_used: "Aktuální počet použití",
        limit: "Limit použití",
        expiration: "Expirace",
      },
    },
    posts: {
      name: "Články",
      fields: {
        id: "id",
        slug: "ID článku, používá se v URL",
        title: "Nadpis",
        updated_at: "Poslední změna",
      },
    },
    manufacturers: {
      name: "Výrobci",
      fields: {
        name: "Název",
      },
    },
  },
};
