import * as React from "react";
import {
  Edit,
  NumberInput,
  ReferenceInput,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin";

const EditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const OrderProductEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="product_name" />
      <TextInput source="gift_product_name" />
      <NumberInput source="quantity" />
      <NumberInput source="product_price" />
      <ReferenceInput source="shipping_id" reference="shipping">
        <SelectInput optionText={item => `${item.title} - ${item.price} Kč`} />
      </ReferenceInput>
      <NumberInput source="shipping_price_per_item" />
    </SimpleForm>
  </Edit>
);
